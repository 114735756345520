.header {
    padding: 14px;
    border: none;

    .title {
        font-size: 16px;
        font-weight: 500;
        color: var(--bs-gray-800);
    }
}

.footer {
    border: none;
}

.body {
    padding-bottom: 0;
}