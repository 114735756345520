.mainContainer {

    @media screen and (max-width: 575px) {
        background-color: white;
        margin-top: 0px !important;
        padding-top: 15px;
    }

    .mainRow {
        max-width: 800px;
        min-width: 300px;
        width: 100%;
        // margin: 0 auto;
        margin: 0 auto;

        .mainCol {
            @media screen and (max-width: 575px) {
                width: 100%;
                padding: 0 !important;
                border: none !important;
            }
        }

        .addWrapper {
            // background-color: aquamarine;
            // margin: 0 auto;

            h2 {
                font-size: 18px;
                line-height: 22px;
                font-weight: 400;
                color: #666;
                margin-bottom: 15px;
            }

            .buttons {
                display: flex;
                gap: 10px;
                justify-content: center;
                margin-top: 20px;
            }
        }

    }
}

.currency {
    padding: 0 0 0 5px;
    border-right: none !important;
    background-color: #fff;
    color: #ccc;
}

.currencyFiled {
    border-left: none;
    padding-left: 5px;
}