@use '../../pages/scss/Colors.module.scss' as colors;

.paginationUL{
    justify-content: center;
    
    a {
        color: #aaa;

        &:hover {
            color: #555
        }
    }

    li.active{
    
        span {
            color: white !important;
            border-color: colors.$primary-color !important;
            background-color: colors.$primary-color !important;

            &:hover {
                color: white !important;
                border-color: colors.$primary-color !important;
                background-color: colors.$primary-color !important;
                z-index: auto;
            }
        }
    }

}

