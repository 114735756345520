body {
    background-color: #fafafa;
    font-family: 'Roboto', sans-serif;
}

h2{
    font-size: 17px;
    color: var(--bs-gray-700);
    text-align: center;
}

.terms {
    color: var(--bs-gray-500);
    font-size: 12px;
}

.terms a{
    text-decoration: none;
    font-weight: 500;
    color: var(--bs-gray-600);

    &:hover {
        color: #000;
    }
}

.auth-cpy {
    font-size: 12px;
    color: var(--bs-gray-500);
}

.form-floating>label {
    color: var(--bs-gray-500);
}

.signup-title{
    font-size: 17px;
    font-weight: 500;
    color: #8e8e8e;
    text-align: center;
}

.social-login-button{
    font-size: 2rem;
    color: #0d6efd;
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
    color: var(--bs-gray-600);
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: var(--bs-gray-400);
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}

.forgot-password{
    font-size: 11px;
    color: var(--bs-gray-600);

    &:hover {
        color: #000;
    }
}

.logoFullAuth{
    width: 180px;
    margin: 10px auto;
    position: relative;
    display: block;
    margin-bottom: 15px;
}