.phone-presentation-edit{
    color: rgb(95, 169, 199) !important;
    font-size: 12px;

    &:hover {
        color: #000 !important;
    }
}

.limits {
    text-align: center;
    margin-top: 5px;
    font-size: 12px;
    color: var(--bs-gray-600);
}

.ava {
    overflow: hidden;
    min-width: 60px;
    min-height: 60px;
    border-radius: 50% !important;
    position: relative;

    .hovered {
        min-width: 60px;
        min-height: 60px;
        width: 60px;
        height: 60px;
        background: var(--bs-gray-800);
        position: absolute;
        opacity: 0.00001;
        border-radius: 50% !important;
        z-index: 3;
        top: 0px;
        left: 0px;
        color: #fff;

        path {
            margin-left: 30px !important;
        }

        &:hover{
            opacity: 0.6;
            cursor: pointer;
        }
    }

}