@use '../../scss/Colors.module.scss' as colors;

.onePostWrapper {
    border-bottom: 1px solid rgb(236, 236, 236);
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0;
    position: relative;

    &:hover .onePost .leftSide {
        @media screen and (min-width: 768px) {
            padding-left: 10px;
        }
        
        cursor: pointer;
    }

    &:last-child {
        border-bottom: none;
    }

    .onePost {
        // background-color: antiquewhite;
        display: flex;

        .leftSide {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            // background-color: aqua;
            flex-grow: 1;
            max-width: 85%;
            transition: padding-left 0.2s ease-in-out;

            .image {
                border-radius: 8px;
                overflow: hidden;
                background-color: rgb(189, 189, 189);
                position: relative;
            }

            .titleWrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                // overflow: hidden;
                margin-right: 50px;

                .title {
                    font-size: 16px;
                    text-transform: capitalize;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                }

                .categories {
                    font-size: 12px;
                    line-height: 16px;
                    color: #b4b4b4;
                    font-weight: 400;
                }

                .price {
                    font-size: 14px;
                    color: green;
                    font-weight: 400;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    
                    i {
                        margin-left: -2px;
                    }
                }
            }
        }

        .rightSide {
            display: flex;
            flex-direction: row;
            // background-color: antiquewhite;
            flex-grow: 1;
            gap: 8px;
            justify-content: flex-end;
            max-width: 15%;
            align-items: center;
            min-width: 88px;

            button {
                // padding: 5px 10px;
                font-size: 16px;
                font-weight: 400;
                max-height: 40px;
                max-width: 40px;
                width: 40px;
                height: 40px;

                &.danger {
                    background-color: #c50000;
                    border: #c50000;

                    &:hover {
                        background-color: #970000;
                        border: #970000;
                    }

                    &:focus {
                        box-shadow: 0 0 0 0.25rem rgba(253, 49, 49, 0.5);;
                    }
                }

                &.edit {
                    background-color: colors.$primary-color;
                    border: colors.$primary-color;

                    &:hover {
                        background-color: #099bc5;
                        border: #099bc5;
                    }

                    &:focus {
                        box-shadow: 0 0 0 0.25rem rgba($color: colors.$primary-color, $alpha: 0.4);
                    }
                }

                &.relist {
                    background-color: #096ac5;
                    border: #096ac5;

                    &:hover {
                        background-color: #0655a0;
                        border: #0655a0;
                    }

                    &:focus {
                        box-shadow: 0 0 0 0.25rem rgba($color: #096ac5, $alpha: 0.4);
                    }
                }
            }

        }
    }

}

.placeholder {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ededed;
    border-radius: 8px;
}

.tempImg {
    overflow: hidden;
    position: relative;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
}


.undone {
    color: rgb(180, 70, 70);
    font-weight: 600;
}