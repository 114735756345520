.oneChat {
    background-color: rgb(245, 245, 245);
    height: 65px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    overflow: hidden;
    position: relative;
    min-height: 65px;

    &.selected {
        background: #fff;
        border-right: none;

        &:hover {
            background-color: #fff;
            cursor: default;
        }
    }

    &:hover {
        background-color: #eee;
    }

    &:last-child {
        border-bottom: 1px solid rgb(245, 245, 245);

        &.selected {
            border-bottom: 1px solid #ccc;
        }
    }

    .avaWrapper {
        margin-left: 8px;

        .ava {
            border-radius: 50%;
        }

        .avaPlaceholder {
            width: 50px;
            height: 50px;
            background-color: #ccc;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                fill: #333;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex-grow: 2;

        @media screen and (max-width: 575px) {
            display: none;
        }
        
        .title {
            white-space: nowrap;
            font-size: 14px;
            line-height: 14px;
        }

        .lastMsg {
            font-size: 11px;
            line-height: 11px;
            color: #666;
            white-space: nowrap;
        }

        .dateTime {
            font-size: 11px;
            line-height: 11px;
            color: #aaa;
            white-space: nowrap;
        }
    }

    .haveNewMessage {
        background-color: #10b9e9;
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 50%;
        margin-right: 8px;
        font-size: 10px;
        color: #fff;

        @media screen and (max-width: 575px) {
            position: absolute;
            z-index: 9;
            top: 5px;
            left: 5px;
        }
    }

}