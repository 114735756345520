.container {
    overflow: hidden;
    position: relative;

    img {
        display: block;
        opacity: 1;
        transition: opacity .3s ease-in-out;
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;

        &.hidden {
            opacity: 0;
        }
    }

    .placeholder {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

