.header {
    height: 65px;
    border-bottom: 1px solid #ccc;
    background: #fff;
    display: flex;
    flex-direction: row;
    padding: 8px;
    gap: 8px;
    flex-wrap: nowrap;

    .avaWrapper {
        flex-grow: 0;
        flex-shrink: 0;

        .ava {
            border-radius: 50%;
            cursor: pointer;
        }

        .avaPlaceholder {
            width: 50px;
            height: 50px;
            background-color: #ccc;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                fill: #333;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 1;
        flex-grow: 1;
        min-width: 100px;
        overflow: hidden;

        .userName {
            white-space: nowrap;
        }

        .aboutItem {
            white-space: nowrap;
            font-size: 14px;
            line-height: 14px;
            color: #777;

            &:hover {
                text-decoration: none;
            }

            span {
                font-size: 12px;
                color: #aaa;
            }
        }
    }

    .rightActions {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 5px;
        background-color: #fff;
        flex-grow: 0;
        flex-shrink: 0;

        .removeButton {
            font-size: 12px;
            color: #666;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        .price{
            color: rgb(1, 119, 7);
            background-color: #fff;
        }
    }
}