

footer .nav {
    margin-top: 12px;
}

footer .cpy {
    margin-top: 0px;
    font-size: 12px;
    color: var(--bs-gray-500);
}

footer .nav-link {
    font-size: 14px;
    padding: 0.2rem 0.5rem;
    color: #777;

    &:hover {
        color: #aaa;
    }

    &:focus {
        color: #aaa;
    }
}

footer .nav-link {
    font-size: 14px;
}

footer .app-version {
    position: fixed;
    color: var(--bs-gray-400);
    font-size: 10px;
    right: 0px;
    bottom: 0px;
    background-color: #fafafaab;
    border-top-left-radius: 8px;
    padding: 3px 8px;
}