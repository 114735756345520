.mainContainer {
    min-width: 320px;
    position: relative;

    @media screen and (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 0px !important;
        width: 100%;
        max-width: 100%;
    }

    .messagesWrapper {
        display: flex;
        flex-direction: row;
        border: 1px solid #ddd;
        background-color: #fff;
        border-radius: 5px;
        padding: 0px;
        max-width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        min-height: 600px;
        height: 600px;

        @media screen and (max-width: 768px) {
            padding-left: 0px;
            padding-right: 0px;
            margin: 0;
            height: 100%;
            min-height: 400px;
            max-height: 75vh;
        }

        @media screen and (max-width: 575px) {
            border-radius: 0px;
            border: none;
            border-bottom: 1px solid #ddd;
        }

        .leftSide {
            background-color: rgb(245, 245, 245);
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 250px;
            width: 250px;
            flex-grow: 3;
            overflow-y: auto;

            @media screen and (max-width: 768px) {
                // width: 100%;
            }

            @media screen and (max-width: 575px) {
                width: 68px;
                flex-grow: 0;
                min-width: 68px;
            }
            
            &::after {
                content: '';
                flex-grow: 2;
                border-right: 1px solid #ccc;
            }

            .alerts {
                padding: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: 1px solid #ccc;

                .haveNoChats {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ccc;
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            .loadMoreChats {
                display: flex;
                justify-content: center;
                padding: 15px;
                border-right: 1px solid #ccc;

                span {
                    font-size: 12px;
                    line-height: 14px;
                    text-decoration: underline;
                    color: #555;
                    cursor: pointer;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

        }

        .rightSide {
            background-color: #fff;
            min-width: 450px;
            width: 450px;
            flex-grow: 1;

            @media screen and (max-width: 768px) {
                // width: 100%;
                min-width: 250px;
            }

            .selectChat {
                background: #ccc;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 20px;
                color: #666;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
            }

            .chatWrapper {
                display: flex;
                flex-direction: column;
                position: relative;
                height: 100%;

            
                .sendArea {
                    background-color: #fff;
                    // height: 48px;
                    padding: 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    .input {
                        &:focus {
                            box-shadow: none;
                        }
                    }

                    .sendButton {
                        // font-size: 12px;
                        // line-height: 12px;
                        // padding: 5px 10px;
                        padding-right: 8px;
                        padding-left: 7px;
                        padding-top: 1px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        background-color: #10b9e9;
                        // border: 1px solid #cdcdcd;
                        border-radius: 50%;
                        width: 34px;
                        height: 34px;
                        transition: all 0.2s ease-in-out 0s;

                        &:hover {
                            background-color: #10b9e9;
                            opacity: 0.7;
                        }

                        &.noTextInput {
                            background-color: #aaa;
                            cursor: default;
                            &:hover {
                                background-color: #aaa;
                                opacity: 1;
                            }
                        }

                        svg {
                            fill: #fff;
                        }

                        .senderSpinner {
                            color: #fff;
                            margin-left: 2px;
                            margin-bottom: 2px;
                        }
                    }
                }
                
            }
        }

        .chekingForChatExist {
            position: absolute;
            background-color: rgb(130, 130, 130);
            opacity: 0.8;
            z-index: 9;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }
}