
$photoMargin: 4px;

.photosWrapper {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 2px;
    position: relative;
    overflow: hidden;
    background-color: rgb(248, 248, 248);

    .insideWrapper {
        // background-color: aqua;

        .uploadSpace {
            // background-color: aquamarine;
            display: flex;
            justify-content: center;

            .dragHere {
                padding: 15px;
                text-align: center;

                span {
                    color: rgb(8, 10, 168);
                    cursor: pointer;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .divWithImages {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            

            .onePhoto {
                position: relative;
                width: calc(25% - $photoMargin);
                margin: calc($photoMargin / 2);
                overflow: hidden;
                border-radius: 4px;

                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
                  }
                
                img {
                    position: absolute; /* Take your picture out of the flow */
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0; /* Make the picture taking the size of it's parent */
                    width: 100%; /* This if for the object-fit */
                    height: 100%; /* This if for the object-fit */
                    object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
                    object-position: center;
                }

                .removeBtn {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-size: 18px;
                    width: 35px;
                    height: 35px;
                    background-color: hsla(0, 0%, 0%, 0.35);
                    right: 0;
                    top: 0;
                    z-index: 1;
                    cursor: pointer;
                    border-bottom-left-radius: 4px;
                    transition: background-color 0.2s ease-in 0s;

                    &:hover {
                        background-color: hsla(0, 0%, 0%, 0.5);
                    }
                }
            }
        }

        .desc {
            // background-color: cadetblue;
            padding: 10px;
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            color: #333;
        }

        .error {
            background-color: rgb(143, 36, 41);
            border-radius: 4px;
            padding: 10px;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            text-align: center;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
        }
    }
    

}