.newMessageScreen {
    background-color: #eee;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .startText {
        color: #333;
        font-size: 22px;
        font-weight: 300;
        padding-bottom: 10px;
    }

    .title {
        color: #333;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        padding-bottom: 5px;
    }

    .price {
        color: #333;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: rgb(1, 119, 7);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -8px;

        span {
            padding-top: 2px;
        }

        svg {
            fill: rgb(1, 119, 7);
        }
    }

    .howTo {
        color: #333;
        font-size: 12px;
        line-height: 20px;
        font-weight: 300;
        margin-top: 10px;
    }

    .avaWrapper {
        padding-bottom: 8px;

        .ava {
            border-radius: 50%;
        }

        .avaPlaceholder {
            width: 50px;
            height: 50px;
            background-color: #ccc;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                fill: #333;
            }
        }
    }

    .sendArea {
        // background-color: #fff;
        // height: 48px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        width: 75%;

        .input {
            &:focus {
                box-shadow: none;
            }
        }

        .sendButton {
            // font-size: 12px;
            // line-height: 12px;
            // padding: 5px 10px;
            padding-right: 8px;
            padding-left: 7px;
            padding-top: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #10b9e9;
            // border: 1px solid #cdcdcd;
            border-radius: 50%;
            width: 34px;
            height: 34px;
            transition: all 0.2s ease-in-out 0s;

            &:hover {
                background-color: #10b9e9;
                opacity: 0.7;
            }

            &.noTextInput {
                background-color: #aaa;
                cursor: default;
                &:hover {
                    background-color: #aaa;
                    opacity: 1;
                }
            }

            svg {
                fill: #fff;
            }

            .senderSpinner {
                color: #fff;
                margin-left: 2px;
                margin-bottom: 2px;
            }
        }
    }

}