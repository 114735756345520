.header-menu {
    background-color: #fff !important;
    border-bottom: 1px solid #f1eeee;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1020;
    box-shadow: rgb(0 0 0 / 6%) 0px 4px 8px 0px;

    .mobileMenuButton {
        // border: 1px solid red;
        font-size: 24px;
        padding: 0px 6px;
        padding-top: 4px;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        margin-left: 10px;

        &:hover {
            opacity: 0.7;
        }

        svg {
            margin-bottom: 5px;
        }
    }

    .mobileLogoA {
        display: block;
        flex-grow: 1;
        text-align: center;
        cursor: pointer;
    }

    .avaContainer {
        @media screen and (max-width: 575px) {
            flex-grow: 0 !important;
        }
    }
}


.header-menu .nav-link {
    padding-bottom: 0;
    padding-top: 18px;
    line-height: 20px;

    &.active {
        color: #000 !important;
        font-weight: 500;
    }
}

.header-menu #collasible-nav-dropdown{
    padding-top: 2px;

    &::after {
        display: none;
    }
}

.header-menu .ava-btn .dropdown-menu{
    right: 15px;
    margin-top: 5px;
}

.header-menu .dropdown-menu {
    margin-top: 42px; 
    box-shadow: rgb(0 0 0 / 10%) 0px 6px 14px 0px;
}

.header-menu .dropdown-item {
    padding-left: 10px;
    color: #333;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.active {
        background-color: unset !important;
        font-weight: 500;
        color: #10b9e9;
        cursor: default;
        

        &:hover {
            background-color: #fff !important;
        }
    }

    svg {
        margin-right: 7px;
        margin-bottom: 2px;
    }

}

.header-menu .dropdown-item:active {
    background-color: #30d2ff;
}

.dropdown-menu-mobile  {
    background-color: white;
    position: absolute;
    width: 100%;
    top: 13px;
    // z-index: 9999;
    border-radius: 0;
    border: none;
    height: 100vh;
    border-top: 1px solid #ccc;

    .dropdown-item {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        padding-left: 17px;

        &.active {
            background-color: unset !important;
            font-weight: 500;

            i {
                color: #333;
            }
            svg {
                fill: #333;
            }
        }

        svg {
            margin-right: 10px;
            margin-bottom: 2px;
            fill: #888;
        }
    }
}

.header-menu .custom-ava {
    cursor: pointer;
    display: flex;
    align-items: center;

    .custom-ava-title {
        margin-right: 10px;
        color: rgb(105, 105, 105);
        font-size: 14px;
        font-weight: 300;
        padding-top: 2px;
    }

    .on-small {
        max-width: 150px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        .custom-ava-title {
            color: black;
        }
    }
}

.backgroundMenuSafe {
    position: relative;
    height: 57px;
}

.navbar-brand {
    margin-right: 10px;

    .menuFullLogo {
        margin-left: 14px;
        margin-top: 0px;
    }
}

.logoLink {
    cursor: pointer;
}

