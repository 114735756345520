.error_page {
    text-align: center;
    position: relative;
    padding: 140px 0;
    z-index: 2;
    font-family: Fira Sans,-apple-system,Open Sans,Helvetica Neue,sans-serif;
}

.error_page__title {
    font-size: 200px;
    font-weight: 400;
    color: #d8d8d8;
    margin-bottom: 0;
}

.error_page__message {
    font-size: 20px;
    color: #393939;
    font-family: Fira Sans,-apple-system,Open Sans,Helvetica Neue,sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
}

.error_page__text {
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
    color: rgba(57,57,57,.5);
}

.error_page__text p {
    color: #aaa;
    line-height: 1.6;
    font-size: 16px;
    font-weight: 300;
    margin: 1.42em 0;
}

.error_page__button {
    border: 0;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-family: Open Sans,-apple-system,Roboto,Helvetica Neue,sans-serif;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    background: #10b9e9;
    color: #fff;
    padding: 6px 15px 7px;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0;
}

.error_page__button:hover {
    background: #4fc6e7;
    color: #fff;
}

.error_page__bg {
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 700px;
    top: 0;
    left: 0;
    z-index: -1;
}

.error_page__bg.layer_1 {
    background-image: url('./Confetti.svg');
    background-size: 1000px;
}

.error_page__bg.layer_2 {
    background-image: url('./Icons.svg');
    background-size: 1000px;
}

@media (max-width: 575px){
    .error_page {
        padding: 30px 0;
    }

    .error_page__title {
        font-size: 90px;
        font-weight: 400;
    }

    .error_page__text {
        padding: 0 10px;
        max-width: 300px;
    }

    .error_page__bg {
        height: 400px;
    }

    .error_page__bg.layer_2 {
        display: none;
    }

    .error_page__bg.layer_1 {
        background-size: 470px;
    }
}