@use '../scss/Colors.module.scss' as colors;

.mainContainer {
    min-width: 320px;

    @media screen and (max-width: 575px) {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 0px !important;
    }

    .postingsWrapper {
        display: flex;
        flex-direction: column;
        border: 1px solid #ddd;
        background-color: #fff;
        border-radius: 5px;
        padding: 20px;
        max-width: 1200px;
        margin: 0 auto;

        @media screen and (max-width: 575px) {
            border-radius: 0px;
            border: none;
            border-bottom: 1px solid #ddd;
        }

        .oneListingWrapper {
            position: relative;

            .listingTitle {
                font-size: 16px;
                line-height: 20px;
                // border-bottom: 1px solid #ececec;
                padding-bottom: 20px;
                font-weight: 500;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                button {
                    padding: 4px 10px;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    min-width: 90px;
                }

                .leftSide {
                    display: flex;
                    flex-direction: column;

                    h4 {
                        font-size: 18px;
                        line-height: 24px;

                        span {
                            font-size: 18px;
                            line-height: 18px;
                            font-weight: 400;
                            padding-right: 20px;
                            color: #ccc;
                        }
                    }

                    span {
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 400;
                        color: #555;
                    }
                }
            }

            .postingsList {
                margin-bottom: 20px;
                position: relative;

                .spinner {
                    width: 100%;
                    text-align: center;
                    padding: 40px;
                }

                .haveNoPosts{
                    color: #ccc;
                    padding: 40px;
                    font-weight: 400;
                }
            }

        }
    }
}