.oneMessageWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80%;
    justify-content: flex-start;
    width: fit-content;
    align-self: flex-end;
    margin-bottom: 8px;

    &.responderSide {
        align-self: flex-start;
        
        .oneMessage {
            flex-direction: row;
            background-color: #eee;

            .textWrapper {
                padding-right: 18px;
                padding-left: 0px;
            }
        }
    }

    .oneMessage {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        background-color: #cdf4ff;
        border-radius: 20px;
        overflow: hidden;
        gap: 3px;
        width: 100%;
        justify-content: flex-start;
        width: fit-content;
        align-self: flex-end;
        min-height: 43px;

        .avaWrapper {
            padding: 7px;

            .ava {
                border-radius: 50%;
                cursor: pointer;
            }

            .avaPlaceholder {
                width: 30px;
                height: 30px;
                background-color: #ccc;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                svg {
                    fill: #333;
                }
            }
        }

        .textWrapper {
            font-size: 14px;
            line-height: 18px;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-right: 0px;
            padding-left: 18px;
        }

    }

    .newMessageReaded {
        color: #aaa;
        font-size: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;

    }

    .haveNewMessage {
        color: #aaa;
        font-size: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 0px;

        svg {
            fill: #10b9e9;
        }
    }

    .delivered {
        color: #bbb;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        gap: 3px;
        font-size: 10px;
    }

    .deliveredAndReaded {
        color: rgb(126, 196, 126);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        gap: 3px;
        font-size: 10px;
    }
}