.chatBody {
    background-color: #fff;
    flex-grow: 2;
    padding: 8px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    

    .upperMessage {
        text-align: center;
        padding: 5px;
        padding-bottom: 10px;
        font-size: 12px;

        .loadMore {
            display: inline;
            cursor: pointer;
            text-decoration: underline;


            &:hover {
                text-decoration: none;
            }
        }

        .lastMessage {
            color: #ccc;
        }

        
    }

}