.alertsZone {
    position: absolute;
    bottom: 20px;
    right: 0px;
    display: flex;
    margin: 20px;
    flex-direction: column;
    opacity: 0.8;

    div {
        text-align: right;
        transition: opacity .5s ease-in-out;
        opacity: 0;

        &.showed {
            opacity: 1 !important;
        }
    }

}



