.modal {

    width: 400px;
    min-width: 400px;
    overflow: hidden;
    position: relative;

    .btnClose {
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: pointer;
        color: #fff;
        padding: 6px 10px;
        z-index: 10000;
        font-size: 22px;
        background-color: rgba(0, 0, 0, 0.6);
        border-bottom-left-radius: 4px;
        transition: opacity 0.2s ease-out 0s;

        &:hover {
            opacity: 0.5;
        }
    }

    .content { 
        overflow: hidden;

        .header {
            padding: 14px;
            border: none;
    
            .title {
                font-size: 16px;
                font-weight: 500;
                color: var(--bs-gray-800);
            }
        }
    
        .footer {
            border: none;
        }
    
        .body {
            padding: 0;
    
            .upgradeImg {
                width: 100%;
               
            }

            .placeholder {
                background-color: rgb(236, 236, 236);
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .underImage {
                padding: 15px;
                padding-top: 20px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                h4{
                    font-size: 18px;
                    line-height: 22px;
                }

                .desc {
                    font-size: 14px;
                    line-height: 18px;
                    color: #333;
                    text-align: center;
                }

                .btnUpgrade {
                    margin-top: 6px;
                }
            }
        }
    }    
}